const de = {
  authTitle: 'Restaurant-Login',
  organizationNumber: 'Kundennumer',
  username: 'Benutzername',
  password: 'Passwort',
  autoLogin: 'Automatisch Anmelden',
  login: 'Login',
  tableOrders: 'Tischbestellungen',
  selfPickUp: 'Selbstabholer',
  inner: 'Hausverkauf',
  phoneMonitor: 'Telefon monitor',
  customerNumber: 'Kundennummer',
  phoneNumber: 'Telefonnumer',
  name: 'Name',
  street: 'Strasse',
  houseNumber: 'Hausnummer',
  plz: 'PLZ',
  city: 'Stadt',
  clientComment: 'Bemerkung',
  deliveryCost: 'Anfahrtskosten',
  clear: 'Löschen',
  goToOrder: 'Bestellung',
  logout: 'Logout',
  callForm: 'Lieferdienst',
  allOrders: 'Alle Bestellungen',
  cookingMonitor: 'Kuchen monitor',
  carMonitor: 'Fahrer monitor',
  report: 'Berichten',
  settings: 'Einstellungen',
  help: 'Hilfe',
  quantity: 'Anzahl',
  article: 'Karte Nr.',
  productName: 'Name von Artiel',
  price: 'Preise',
  back: 'Zuruck',
  freeDelivery: 'Anfahrtkosten Frei',
  print: 'Drucken',
  comment: 'Hinweis',
  goods: 'Artikel',
  additions: 'Zutaten',
  discount: 'Rabatt',
  totalPrice: 'Gesamt preis',
  tax_7: 'MWST 7%',
  tax_19: 'MWST 19%',
  tax: 'MWST',
  add: 'Hinzufügen',
  articlePrices: 'Artikel',
  additionalRequests: 'Extra Wünsch',
  online: 'Online Bezahlt',
  EC: 'Gerät',
  bar: 'BAR',
  progSettings: 'Programmeinstellungen',
  printSettings: 'Drucker',
  userSettings: 'Benutzer Verwalten',
  voipSettings: 'Telephone',
  menuSettings: 'Speisekarte',
  deliverySettings: 'Anfahrt Kosten',
  categorySettings: 'Kategorie Anlegen / Ändern',
  articleSettings: 'Artikel- Zutaten Anlegen / Ändern',
  saleCategorySettings: 'Rabatt für Kategorie / %',
  setMenu: 'Menü',
  categoryName: 'Name von Kategorie',
  subcategoryName: 'Neme von Unterkategorie',
  printer: 'Anf welche Drucker senden',
  programIcon: 'icon für Program',
  onlineShopIcon: 'Bild für Online Shop',
  sizesInCategory: 'Anzahl von Großen in Kategorie',
  nameOfSize: 'Name von Großer',
  save: 'Speichern',
  dontSave: 'Nicht speichern',
  doYouWantToSaveChanges: 'Möchten Sie Ihre Änderungen speichern?',
  categoryAlreadyExistsDoYouWantToRewriteIt:
    'Kategorie existiert bereits. Willst du es neu schreiben?',
  rewrite: 'Umschreiben',
  cancel: 'Stornieren',
  addSubcategory: 'Unterkategorie hinzufügen',
  upload: 'Laden',
  addYourCategory: 'Bitte legen sie hie ihre kategorie ein',
  articleNumber: 'Karten nummer',
  description: 'Beschreibung',
  type: 'Type',
  allergens: 'Allergen',
  additionalInfo: 'Zusatzstoffen',
  numOfFreeIngridients: 'Anzahl kostenlose Zutaten',
  deliveryCostArticle: 'Liefer Preis',
  restaurant: 'Restaurant',
  inActions: 'Bei Action anzeigen',
  outOfSale: 'Artikel ausverkauft',
  articleAlreadyExistsDoYouWantToRewriteIt:
    'Artikel existiert bereits. Willst du es neu schreiben?',
  printerName: 'Drucker Name',
  quantityOfCopies: 'Anzahl von Exemplaren',
  frontOrBackPrinter: 'Drucker steht vorne für Fahrer oder hinten für Küche',
  pleaseTypeNameOfYourPrinter: 'Bitte Legen Sie hier Ihre Druckername, Beschreibung, Type ein',
  printerAlreadyExistsDoYouWantToRewriteIt:
    'Drucker Existiert Bereits möchten Sie es neu Schreiben',
  notIncludeInMin: 'von Mindestbestellwert ausnehmen',
  telefonProvider: 'Telefon Provider',
  domain: 'Domain',
  registar: 'Registar',
  voipHeader: 'Bitte richten Sie telefonie Einstellungen für VOIP-Telefonie/ Kundenmonitor.',
};

export default de;
