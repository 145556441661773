import React from 'react';

export const KitchenMonitorIcon = ({ ...props }) => (
  <svg viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M46.373 36.4422C48.476 31.8988 48.8703 26.7408 47.4827 21.9265C46.0951 17.1122 43.02 12.9691 38.8286 10.2669C34.6372 7.56476 29.6146 6.48729 24.694 7.23471C19.7735 7.98214 15.2895 10.5036 12.0754 14.3307C8.86119 18.1578 7.13534 23.0302 7.21848 28.0426C7.30163 33.055 9.18812 37.8665 12.5274 41.5833C15.8667 45.3 20.4318 47.6692 25.3744 48.2506C30.317 48.8319 35.301 47.586 39.4005 44.7442"
      stroke="#8092A0"
      strokeWidth="4"
    />
    <path d="M31.7222 32.5356L55.4591 49.1145" stroke="#8092A0" strokeWidth="4" />
  </svg>
);
