import React from 'react';

export const VoipStatusIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    width="72"
    height="62"
    viewBox="0 0 72 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51.2211 21.3006C49.0379 16.6114 45.3368 12.7986 40.7182 10.4809C36.0997 8.1631 30.8347 7.47631 25.7774 8.53194C20.7202 9.58757 16.1674 12.3237 12.8578 16.2963C9.54826 20.269 7.67601 25.2451 7.54487 30.4174C7.41372 35.5896 9.03138 40.6545 12.1354 44.7903C15.2394 48.9261 19.6476 51.8901 24.6448 53.2015C29.642 54.5128 34.9351 54.0946 39.6651 52.0146C44.3952 49.9346 48.2848 46.3149 50.7028 41.7427"
      stroke="#F7F8F9"
      strokeWidth="4"
    />
    <line
      y1="-2"
      x2="17.8653"
      y2="-2"
      transform="matrix(0.751444 0.659797 -0.658642 0.752457 34.7028 25.1062)"
      stroke="#F7F8F9"
      strokeWidth="4"
    />
    <line
      y1="-2"
      x2="30.4993"
      y2="-2"
      transform="matrix(0.782487 -0.622667 0.621485 0.783426 48.1347 36.8625)"
      stroke="#F7F8F9"
      strokeWidth="4"
    />
  </svg>
);
