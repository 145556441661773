const ru = {
  authTitle: 'Авторизация',
  organizationNumber: 'Номер организации',
  username: 'Имя пользователя',
  password: 'Пароль',
  autoLogin: 'Запомнить меня',
  login: 'Войти',
  tableOrders: 'Tischbestellungen', // TODO: Перевод
  selfPickUp: 'С собой', // TODO: Перевод
  inner: 'Hausverkauf', // TODO: Перевод
  phoneMonitor: 'Telefon monitor', // TODO: Перевод
  customerNumber: 'Номер клиента',
  phoneNumber: 'Номер телефона',
  name: 'Имя',
  street: 'Улица',
  houseNumber: 'Номер дома',
  plz: 'Почтовый индекс',
  city: 'Город',
  clientComment: 'Комментарий клиента',
  deliveryCost: 'Доставка',
  clear: 'Очистить',
  goToOrder: 'Продолжить заказ',
  logout: 'Выйти',
  callForm: 'Доставка',
  allOrders: 'Все заказы',
  cookingMonitor: 'Монитор кухни',
  carMonitor: 'Монитор доставки',
  report: 'Отчет',
  settings: 'Настройки',
  help: 'Помощь',
  quantity: 'Кол-во',
  article: 'Арт.',
  productName: 'Номенклатура',
  price: 'Цена',
  back: 'Назад',
  freeDelivery: 'Бесплатная доставка',
  print: 'Печать',
  comment: 'Комментарий заказчика',
  goods: 'Товары',
  additions: 'Добавки',
  discount: 'Скидка',
  totalPrice: 'Общая цена',
  tax_7: 'Налог 7%',
  tax_19: 'Налог 19%',
  tax: 'Налог',
  add: 'Добавить',
  articlePrices: 'Номенклатура',
  additionalRequests: 'Доп. пожелания',
  online: 'Оплачено онлайн',
  EC: 'Карточкой',
  bar: 'Наличными',
  progSettings: 'Настройки Программы',
  printSettings: 'Принтеры',
  userSettings: 'Настройки пользователей',
  voipSettings: 'Телефон',
  menuSettings: 'Меню',
  deliverySettings: 'Доставка',
  categorySettings: 'Категории',
  articleSettings: 'Артикли / Добавки',
  saleCategorySettings: 'Скидки для Категорий',
  setMenu: 'Меню',
  categoryName: 'Название Категории',
  subcategoryName: 'Название Подкатегории',
  printer: 'На какой принтер печатать',
  programIcon: 'иконка для программы',
  onlineShopIcon: 'Фото для онлайн шопа',
  sizesInCategory: 'Количество размеров в категории',
  nameOfSize: 'Название Размера',
  save: 'Сохранить',
  dontSave: 'Не сохранять',
  doYouWantToSaveChanges: 'Вы хотите сохранить изменения?',
  categoryAlreadyExistsDoYouWantToRewriteIt: 'Категория уже существует. Вы хотите перезаписать ее?',
  rewrite: 'Перезаписать',
  cancel: 'Сбросить',
  addSubcategory: 'Добавить подкатегорию',
  upload: 'Загрузить',
  addYourCategory: 'Добавление новой категории',
  articleNumber: 'Номер артикля',
  description: 'Описание',
  type: 'Тип',
  allergens: 'Аллергены',
  additionalInfo: 'Добавки',
  numOfFreeIngridients: 'Количество бесплатных ингридиентов',
  deliveryCostArticle: 'Цена на дом',
  restaurant: 'В ресторане',
  inActions: 'Участвует в акциях',
  outOfSale: 'Товар распродан',
  articleAlreadyExistsDoYouWantToRewriteIt: 'Артикль существует, вы хотите перезаписать его?',
  printerName: 'Имя принтера',
  quantityOfCopies: 'Количество копий',
  frontOrBackPrinter: 'Принтер передний для водителя или задний для кухни',
  pleaseTypeNameOfYourPrinter: 'Пожалуйста, введите название вашего принтера и описание',
  printerAlreadyExistsDoYouWantToRewriteIt: 'Принтер уже существует вы хотите переписать его',
  notIncludeInMin: 'Не учитывать в мин. стоимости заказа',
  telefonProvider: 'Телефонный провайдер',
  domain: 'Домен',
  registar: 'Registar',
  voipHeader: 'Пожалуйста, настройте параметры телефонии для VOIP телефонии / монитора клиента',
};

export default ru;
